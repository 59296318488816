<template>
	<div>
		<Row>
			<Col class="body-top-content">
			<div class="content-i" style="background: rgba(77,124,254,.2);">
				<div class="content-item" style="background: #4d7cfe;">
					<Tooltip placement="top" content="舌诊">
						<i class="iconfont icon-tongue"></i>
					</Tooltip>
				</div>
			</div>
			<div>
				<div class="content-number">{{ statistics[1].amount }}</div>
				<div class="content-tips">历史调用量 (次)</div>
			</div>
			<div style="margin-left: 20px;">
				<div class="content-number">{{ statistics[1].amount3 }}</div>
				<div class="content-tips">上月调用量 (次)</div>
			</div>
			<div style="margin-left: 20px;">
				<div class="content-number">{{ statistics[1].amount4 }}</div>
				<div class="content-tips">本月调用量 (次)</div>
			</div>
			</Col>
			<Col class="body-top-content">
			<div class="content-i" style="background: rgba(254, 129, 252, 0.2);">
				<div class="content-item" style="background: rgb(254, 129, 252);">
					<Tooltip placement="top" content="面诊">
						<i class="iconfont icon-gerenyonghutouxiang"></i>
					</Tooltip>
				</div>
			</div>
			<div>
				<div class="content-number">{{ statistics[2].amount }}</div>
				<div class="content-tips">历史调用量 (次)</div>
			</div>
			<div style="margin-left: 20px;">
				<div class="content-number">{{ statistics[2].amount3 }}</div>
				<div class="content-tips">上月调用量 (次)</div>
			</div>
			<div style="margin-left: 20px;">
				<div class="content-number">{{ statistics[2].amount4 }}</div>
				<div class="content-tips">本月调用量 (次)</div>
			</div>
			</Col>
			<Col class="body-top-content">
			<div class="content-i" style="background: rgba(250, 208, 88, 0.2);">
				<div class="content-item" style="background: rgb(250, 208, 88);">
					<Tooltip placement="top" content="手诊">
						<i class="iconfont icon-hand"></i>
					</Tooltip>
				</div>
			</div>
			<div>
				<div class="content-number">{{ statistics[5].amount }}</div>
				<div class="content-tips">历史调用量 (次)</div>
			</div>
			<div style="margin-left: 20px;">
				<div class="content-number">{{ statistics[5].amount3 }}</div>
				<div class="content-tips">上月调用量 (次)</div>
			</div>
			<div style="margin-left: 20px;">
				<div class="content-number">{{ statistics[5].amount4 }}</div>
				<div class="content-tips">本月调用量 (次)</div>
			</div>
			</Col>
			<Col class="body-top-content" style="margin-right: 0px;">
			<div class="content-i" style="background: rgba(254, 150, 129, 0.2);">
				<div class="content-item" style="background: rgb(254, 150, 129);">
					<Tooltip placement="top" content="辨证">
						<i class="iconfont icon-yiyaoyuyiliao"></i>
					</Tooltip>
				</div>
			</div>
			<div>
				<div class="content-number">{{ statistics[4].amount }}</div>
				<div class="content-tips">历史调用量 (次)</div>
			</div>
			<div style="margin-left: 20px;">
				<div class="content-number">{{ statistics[4].amount3 }}</div>
				<div class="content-tips">上月调用量 (次)</div>
			</div>
			<div style="margin-left: 20px;">
				<div class="content-number">{{ statistics[4].amount4 }}</div>
				<div class="content-tips">本月调用量 (次)</div>
			</div>
			</Col>
			<Col class="body-top-content">
			<div class="content-i" style="background: rgba(85, 255, 255, 0.2);">
				<div class="content-item" style="background: rgb(85, 255, 255);">
					<Tooltip placement="top" content="智能问诊">
						<i class="iconfont icon-ico_yiyuanguanjia_yizhuchaxun"></i>
					</Tooltip>
				</div>
			</div>
			<div>
				<div class="content-number">{{ statistics[8].amount }}</div>
				<div class="content-tips">历史调用量 (次)</div>
			</div>
			<div style="margin-left: 20px;">
				<div class="content-number">{{ statistics[8].amount3 }}</div>
				<div class="content-tips">上月调用量 (次)</div>
			</div>
			<div style="margin-left: 20px;">
				<div class="content-number">{{ statistics[8].amount4 }}</div>
				<div class="content-tips">本月调用量 (次)</div>
			</div>
			</Col>
			<Col class="body-top-content">
			<div class="content-i" style="background: rgba(0, 255, 0, 0.2);">
				<div class="content-item" style="background: rgb(0, 255, 0);">
					<Tooltip placement="top" content="体质">
						<i class="iconfont icon-zhongyitizhibianshi"></i>
					</Tooltip>
				</div>
			</div>
			<div>
				<div class="content-number">{{ statistics[3].amount }}</div>
				<div class="content-tips">历史调用量 (次)</div>
			</div>
			<div style="margin-left: 20px;">
				<div class="content-number">{{ statistics[3].amount3 }}</div>
				<div class="content-tips">上月调用量 (次)</div>
			</div>
			<div style="margin-left: 20px;">
				<div class="content-number">{{ statistics[3].amount4 }}</div>
				<div class="content-tips">本月调用量 (次)</div>
			</div>
			</Col>
		</Row>
		<div class="body">
			<Form :model="search" inline label-colon>
				<FormItem>
					<Input style="width: 200px;margin-right: 10px;" v-model="search.uname" placeholder="商户名称"></Input>
					<Button type="primary" @click="init">搜索</Button>
				</FormItem>
			</Form>
			<Table :data="data" :columns="columns" :loading="table_loading">
				<template slot="operation" slot-scope="{row}">
					<Button @click="sel_number(row.id)" type="primary">查看次数</Button>
				</template>
			</Table>
			<Page style="text-align: right;margin-top: 10px;" :current="search.page" :total="search.total"
				:page-size="search.page_size" :page-size-opts='[10,15,20]' show-total show-sizer @on-change="up_page"
				@on-page-size-change="up_limit"></Page>
		</div>
		<Modal title="次数查询" :footer-hide="true" v-model="modal_show" :width="40">
			<Table :data="modal_data" :columns="modal_columns" :loading="modal_loading">
				<template slot="type" slot-scope="{row}">
					{{ modal_array[row.type] }}
				</template>
			</Table>
		</Modal>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				search: {
					uname: '',
					page: 1,
					total: 0,
					page_size: 10
				},
				modal_show: false,
				table_loading: false,
				modal_loading: false,
				columns: [{
					title: 'ID',
					key: 'id',
					minWidth: 100,
					align: 'center'
				}, {
					title: '手机号',
					key: 'phone',
					minWidth: 150,
					align: 'center'
				}, {
					title: '商户名称',
					key: 'uname',
					minWidth: 150,
					align: 'center'
				}, {
					title: '操作',
					slot: 'operation',
					minWidth: 250,
					align: 'center'
				}],
				data: [],
				statistics: [],
				modal_data: [],
				modal_array: ['', '舌诊', '面诊', '体质', '辨证', '手诊', '', '', '智能问诊'],
				modal_columns: [{
					title: 'AI名称',
					slot: 'type',
					minWidth: 100
				},{
					title: '剩余调用量（次）',
					key: 'surplus_amount',
					minWidth: 150,
					align: 'center'
				}, {
					title: '历史调用量（次）',
					key: 'amount',
					minWidth: 150,
					align: 'center'
				}, {
					title: '上月调用量（次）',
					key: 'amount3',
					minWidth: 150,
					align: 'center'
				},{
					title: '本月调用量（次）',
					key: 'amount4',
					minWidth: 150,
					align: 'center'
				}]
			}
		},
		created() {
			this.init()
		},
		methods: {
			init() {
				this.table_loading = true
				let _this = this
				this.requestApi('/adm/get_statistics', {
					search: this.search
				}).then(
					(res) => {
						_this.table_loading = false
						_this.data = res.data.data
						_this.statistics = res.statistics
						_this.search.page = res.data.current_page
						_this.search.page_size = res.data.per_page
						_this.search.total = res.data.total
					}
				)
			},
			up_page(page) {
				this.search.page = page
				this.init()
			},
			up_limit(limit) {
				this.search.page_size = limit
				this.init()
			},
			sel_number(id) {
				let _this = this
				this.modal_loading = true
				this.modal_show = true
				this.requestApi('/adm/get_ai_package', {
					id
				}).then(
					(res) => {
						_this.modal_loading = false
						_this.modal_data = res.data
					}
				)
			}
		}
	}
</script>

<style scoped="scoped">
	.body {
		background-color: #fff;
		padding: 10px;
	}

	.body-top-content {
		background-color: #fff;
		width: 24.4%;
		display: flex;
		align-items: center;
		border-radius: 6px;
		height: 100px;
		margin-bottom: 10px;
		margin-right: 13.7px;
	}

	.content-i {
		border-radius: 50%;
		width: 56px;
		height: 56px;
		margin-left: 24px;
		margin-right: 24px;
		text-align: center;
	}

	.content-item {
		border-radius: 22px;
		width: 44px;
		height: 44px;
		line-height: 44px;
		margin-top: 6px;
		margin-left: 6px;
		text-align: center;
		color: #fff;
	}

	.content-number {
		font-size: 20px;
		font-weight: 700;
	}

	.content-tips {
		font-size: 12px;
		color: #b4c1ce;
	}
</style>
